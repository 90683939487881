import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Toggle from './toggle';
import personal from '../images/personal.jpg';

const Navbar = ({ theme, setTheme }) => {
  const [menu, setMenu] = useState(false);
  const [flipClass, setFlipClass] = useState('');
  const [menuFlip, setMenuFlip] = useState('');
  const navigate = useNavigate();

  const flipOutToClose = () => {
    setFlipClass('flip-out-menu');
    setMenuFlip('flip-menu-close');
    setTimeout(() => {
      setMenu(true);
      setMenuFlip('flip-menu-in');
      setFlipClass('flip-in-close');

    }, 300); 
  };

  const flipOutToMenu = () => {
    setFlipClass('flip-out-close');
    setMenuFlip('flip-menu-out');
    setTimeout(() => {
      setMenu(false);
      setFlipClass('flip-in-menu');
      setMenuFlip('flip-menu-in');
    }, 300); 
  };

  const toggleNavbar = () => {
    if (menu) {
      flipOutToMenu();
    } else {
      flipOutToClose();
    }
  };

  return (
    <div className="navbarContainer">
      <div className={theme === 'light' ? 'navbar-light' : 'navbar-dark'}>
        <div className="nav-menu" onClick={toggleNavbar}>
          {!menu ? (
            <p onClick={toggleNavbar} className={`nav-open ${flipClass} open-menu-button`}>
              <FontAwesomeIcon icon={faBars} className="nav-icon" />
              Menu
            </p>
          ) : (
              <p onClick={toggleNavbar} className={`nav-close ${flipClass} open-menu-button`}>
              <FontAwesomeIcon icon={faTimes} className="nav-icon" />
              Close
            </p>
          )}
          {menu && <Menu theme={theme} setTheme={setTheme} flipClass={flipClass} />}
        </div>
        <div className={`nav-name nav-name-${theme}`}>
          <Link to="/">
            <img src={personal} alt="Johann.V" className="nav-image" />
          </Link>
          <Link to="/">Johann.V</Link>
        </div>
        <div className="nav-link">
          <Toggle theme={theme} setTheme={() => setTheme(theme === 'light' ? 'dark' : 'light')} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const Menu = ({ theme, setTheme, flipClass }) => {
  return (
    <div className={`menu-${theme} ${flipClass}`}>
      <div className={`menu-item-${theme}`}>
        <Link to="/">Home</Link>
      </div>
      <div className={`menu-item-${theme}`}>
        <Link to="/about">About</Link>
      </div>
      <div className={`menu-item-${theme}`}>
        <Link to="/work">Work</Link>
      </div>
      <div className={`menu-item-${theme}`}>
        <Link to="/blogs">Blog</Link>
      </div>
      <div className={`menu-item-${theme}`}>
        <Link to="/contact">Contact</Link>
      </div>

    </div>
  );
};
