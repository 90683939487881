import React from 'react'
import "../../css/components/socialbuttons.css";
import { faGithub, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
function Socialbuttons({ theme }) 
    {
        let navigate = useNavigate();
        const handleOpen = (url) =>  
        {
            //redirect to the url
            window.open(`https://${url}`, '_blank');
        }
        return (
                <div className="socials-container">
                    
                <div className={`social-button-${theme} social-button`} onClick={() => handleOpen('www.github.com/elpetas')} >
                        <span className="button-text"><FontAwesomeIcon icon={faGithub} className={`social-icon-${theme}`}/> github</span>
                    <span className="button-text-hover"><FontAwesomeIcon icon={faArrowRight} className={`social-icon-${theme}`} />Link</span>
                    </div>
                <div className={`social-button-${theme} social-button`} onClick={() => handleOpen('www.linkedin.com/in/johann-vargas-a640581aa/')} >
                    <span className="button-text"><FontAwesomeIcon icon={faLinkedin} className={`social-icon-${theme}`} /> linkedin</span>
                    <span className="button-text-hover"><FontAwesomeIcon icon={faArrowRight} className={`social-icon-${theme}`} />Link</span>
                     </div> 
                <div className={`social-button-${theme} social-button`} onClick={() => handleOpen('www.instagram.com/johannvargasu')} >
                    <span className="button-text"><FontAwesomeIcon icon={faInstagram} className={`social-icon-${theme}`} /> instagram</span>
                    <span className="button-text-hover"><FontAwesomeIcon icon={faArrowRight} className={`social-icon-${theme}`} />Link</span>
                     </div>     
                </div>

            
        )
    }

export default Socialbuttons
