import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { UserProvider } from './context/userContext';
import HttpsRedirect from 'react-https-redirect';
import Layout from './components/layout';
const App = React.lazy(() => import('./App'));
const P404 = React.lazy(() => import('./pages/404'));
const About = React.lazy(() => import('./pages/about'));
const Contact = React.lazy(() => import('./pages/contact'));
const Github = React.lazy(() => import('./pages/github'));
const HappyGifter = React.lazy(() => import('./pages/happyGifter'));
const Allkka = React.lazy(() => import('./pages/allkka'));

const Work = React.lazy(() => import('./pages/work'));
const SelectedWork = React.lazy(() => import('./components/work/selectedWork'));   
const Articles = React.lazy(() => import('./pages/articles'));
const Blog = React.lazy(() => import('./components/articles/blog'));
const BrowserRouter = createBrowserRouter([
  {
    path: '/',
    element: <Layout><App/></Layout>,
  },
  {
    path: '/about',
    element: <Layout><About/></Layout>,
  },
  {
    path: '/contact',
    element: <Layout><Contact/></Layout>,
  },
  {
    path: '/github',
    element: <Layout><Github/></Layout>,
  },
  {
    path: '/happygifter',
    element: <Layout><HappyGifter/></Layout>,
  },
  {
    path: '/allkka',
    element: <Layout><Allkka/></Layout>,
  },
  { 
    path: '/work',
    element: <Layout><Work/></Layout>,
  },
  {
    path: '/work/:id',
    element: <Layout><SelectedWork/></Layout>,
  },
  { 
    path: '/blogs',
    element: <Layout><Articles /></Layout>,
  },
  {
    path: '/blogs/:id',
    element: <Layout><Blog /></Layout>,
  },
  {
    path: '*',
    element: <Layout><P404/></Layout>,
  },

]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HttpsRedirect>
      <UserProvider>
        <Suspense fallback={<div style={{minHeight:"100vh" ,backgroundColor:"black",color:"white"}}>Loading...</div>}>
          <RouterProvider router={BrowserRouter}/>
        </Suspense>
      </UserProvider>
    </HttpsRedirect>
  </React.StrictMode>
);


reportWebVitals();
