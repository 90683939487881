import React from "react";
import "../css/components/footer.css";
import {Link} from "react-router-dom";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Socialbuttons from "./home/socialbutton";
function Footer({theme}) {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
    return (
        <div className={`footer footer-${theme}`}>
            <div className={`footer-container footer-container-${theme}`}>
               <div className="footer-text">
                    <h1>
                        Ready to Collaborate?
                    </h1>
                    <p>
                        Let's connect here
                    </p>
                    <a href="mailto:johann.777.jv@gmail.com " className={`footer-link footer-link-${theme}`}>
                        johann.777.jv@gmail.com 
                    </a>
               </div>
               <div className="footer-nav">
                    <Link to="/" className={`footer-nav-link footer-link-${theme}`}>
                        Home
                    </Link>
                    <Link to="/about" className={`footer-nav-link footer-link-${theme}`}>
                        About
                    </Link>
                    <Link to="/work" className={`footer-nav-link footer-link-${theme}`}>
                        work
                    </Link>
                    <Link to="/contact" className={`footer-nav-link footer-link-${theme}`}>
                        Contact
                    </Link>
               </div>
                <div className="copyright">
                    <p>
                        © 2024 Johann Vargas
                    </p>
                    <p onClick={scrollToTop}>
                        <FontAwesomeIcon icon={faArrowAltCircleUp} className={`footer-icon footer-icon-${theme}`}  />
                        <span> </span>
                        Back to Top
                    </p>
                </div>
                </div>
                <Socialbuttons theme={theme}/>
        </div>
    );
}
export default Footer;