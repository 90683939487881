import React from 'react'
import '../css/toggle.css';
function Toggle({theme, setTheme}) {
  return (
    <div className="toggle-container">
        <input type="checkbox" id="toggle" onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')} className='toggle'/>
        <label htmlFor="toggle"></label>
      
    </div>
  )
}

export default Toggle
