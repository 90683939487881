
import React, {useState,useContext } from 'react';
import Header from './navbar';
import Footer from './footer';
import '../css/layout.css';
import { userContext } from '../context/userContext';

const Layout = ({ children }) => {
    const {theme, setTheme} = useContext(userContext);

    return (
        <div className={theme}>
        <Header theme={theme} setTheme={setTheme} />
        {children}
        <Footer theme={theme} />
        </div>
    );
    };
    
export default Layout;